@import '_var.scss';

@media (max-width: 1441px) {
    .form {
        .choice-row {
            .btn {
                flex: none;
            }
        }
    }

    .services-block {
        .text {
            padding: 40px 40px 40px 10px;
        }
    }

    .statistic-block {
        margin-left: -60px;

        &--item {
            margin: 0 0 0 60px;
        }
    }
}


/******************************************************************/

@media (max-width: 1361px) {
    .control-block {
        display: none;
    }

    .info-user {
        &--item {
            flex: 33.333%;
            max-width: 33.333%;
        }
    }
}

/******************************************************************/

@media (max-width: 1281px) {
    .wrapper {
        padding-bottom: 45px;
    }
    .section {
        margin: 45px 0;
    }

    p {
        margin: 20px 0 0;
        font-size: 16px;
        line-height: 24px;

        &:first-child {
            margin-top: 0;
        }
    }

    h2 {
        font-size: 45px;
        line-height: 48px;
    }
    h3 {
        font-size: 45px;
        line-height: 48px;
    }


    .title-wrap {
        .title {
            max-width: 600px;
            margin-top: 30px;
        }
    }
    .sup-title {
        font-size: 13px;
        line-height: 12px;
        padding: 8px 13px;
    }


    .tabs {
        &-head {
            margin-bottom: -5px;

            &-item,
            .delimiter {
                margin-bottom: 5px;
                padding-bottom: 5px;
                font-size: 39px;
            }
            .delimiter {
                padding: 0 13px;
            }
        }
    }


    .pagination {
        margin-top: 80px;
    }


    .button-wrap {
        max-width: 160px;

        .btn {
            padding: 7px 20px;
            font-size: 14px;
        }
        .remove {
            font-size: 12px;

            img {
                max-width: 10px;
            }
        }
    }


    /***/

    .form {
        margin-top: 60px;

        .row {
            margin-top: 20px;
        }

        .forgot {
            margin-left: 30px;
        }

        .choice-row {
            flex-direction: column;

            .btn {
                width: 100%;
                max-width: 560px;
            }

            .delimiter {
                padding: 40px 0;
            }
        }

        .statistic-row {
            .statistic-block {
                &--item {
                    flex-direction: column;
                }

                .text {
                    padding: 10px 0 0;
                }
            }
        }
    }


    /***/

    .first-block {
        margin-bottom: 40px;

        h1 {
            font-size: 52px;
            line-height: 46px;
        }
        h2 {
            font-size: 40px;
            line-height: 45px;
        }
        p {
            margin-top: 20px;
            font-size: 20px;
            line-height: 30px;
        }
    }


    /***/

    .goals-wrap {
        .statistic-block-wrap {
            margin-top: 90px;
        }
        .statistic-block {
            padding-left: 186px;
        }
    }
    .goals {
        .image {
            max-width: 150px;
        }
        .text {
            padding-left: 36px;

            p {
                max-width: 670px;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
    .goals-list {
        margin-top: 40px;
        padding-left: 186px;

        &--text {
            font-size: 16px;
            line-height: 20px;
        }
    }


    /***/

    .benefits-wrap {
        margin: 90px 0 0;
    }
    .benefits-list {
        margin-top: 40px;

        &--text {
            font-size: 16px;
            line-height: 22px;
        }
    }


    /***/

    .services-block-wrap {
        margin-top: 70px;
    }


    /***/

    .statistic-block {
        margin: 0 0 0 -20px;

        &-wrap {
            margin-top: 120px;
        }

        &--item {
            margin: 0 0 0 20px;

            &:first-child {
                .num-wrap {
                    .icon {
                        max-width: 26px;
                    }
                }
            }
        }

        .num-wrap {
            .icon {
                max-width: 35px;
            }
        }
        .num {
            font-size: 78px;
            line-height: 73px;
        }

        .text {
            margin-top: 30px;
            font-size: 18px;
            line-height: 24px;
        }
    }


    /***/

    .info-user {
        margin-top: -50px;

        &--item {
            padding: 50px 50px 0 0;
        }
        &--label {
            font-size: 10px;
        }
        &--text {
            margin-top: 18px;
            font-size: 17px;
            line-height: 20px;
        }
        .name {
            padding-left: 70px;

            .letter {
                left: 0;
            }
            .text {
                max-width: 170px;
                font-size: 17px;
                line-height: 19px;
            }
        }
    }

    .letter {
        width: 47px;
        height: 47px;
        font-size: 21px;
    }


    /***/

    .comment-list {
        &--text {
            font-size: 14px;
            line-height: 18px;
        }
    }


    /***/

    .entries-list {
        .head {
            .col {
                font-size: 14px;
                line-height: 14px;
            }
        }

        .body {
            margin: 30px 0 0;
            padding: 30px 0 0;
        }

        &--text {
            padding: 0;
        }
    }


    .about-block {
        .title-wrap {
            margin-bottom: 30px;
        }
    }


    /***/

    .authorize-page {
        .tabs {
            &-head {
                &-item,
                .delimiter {
                    font-size: 45px;
                    line-height: 48px;
                }
            }
        }
    }
}

/******************************************************************/

@media (max-width: 1025px) {
    .form {
        .statistic-row {
            flex-direction: column;
            align-items: flex-start;

            .statistic-block {
                margin: 40px 0 0 -40px;
                padding: 0;
            }
        }
    }

    .goals-wrap {
        .statistic-block {
            &-wrap {
                margin-top: 80px;
            }

            &--item {
                flex-direction: column;
            }

            .text {
                margin: 20px 0 0;
                padding: 0;
            }
        }
    }


    .pagination {
        &--item {
            position: relative;
            border-left-width: 1px;
            margin-left: -1px;

            &.prev {
                border-radius: 5px 0 0 5px;
            }
            &.next {
                border-radius: 0 5px 5px 0;
            }

            &.first,
            &.last {
                display: none;
            }
        }
    }

    .entries-list-label {
        display: none;
    }

    .entries-list {
        .head {
            flex-wrap: wrap;
            margin: -30px -30px 0 0;

            .col {
                flex: 33.333%;
                max-width: 33.333%;
                padding: 30px 30px 0 0;

                &.button {
                    display: block;
                }

                .btn {
                    width: 100%;
                    padding: 15px 30px;
                    font-size: 13px;
                }
            }
        }

        .body {
            align-items: stretch;
            flex-direction: column;
        }

        .button-wrap {
            max-width: none;

            .btn {
                display: none;
            }
            .remove {
                margin: 20px 0 0 auto;
            }
        }
    }


    /***/

    .authorize-block {
        .button-wrap {
            flex: none;
        }
    }
}

/******************************************************************/

@media (max-width: 769px) {
    h3 {
        font-size: 34px;
        line-height: 38px;
    }


    .tabs {
        margin-top: 80px;
    }


    .button-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-width: none;
        padding: 24px 0 0;

        .btn {
            width: auto;
            padding: 7px 40px;
        }
        .remove {
            margin: 0;
        }
    }


    .number-list {
        &--item {
            padding-left: 100px;
            font-size: 18px;
            line-height: 24px;

            &:before {
                top: 15px;
            }
        }
    }


    .form {
        .row {
            flex-direction: column;
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }
        }

        .col {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    /***/

    .first-block {
        min-height: 0;
        padding: 150px 0 0;
        background: none !important;

        .mobile-bg {
            display: block;
        }

        p {
            display: none;
        }

        .buttons {
            flex-direction: column;

            .btn,
            .search-wrap {
                margin: 20px 0 0;

                &:first-child {
                    margin-top: 0;
                }
            }
            .search-wrap {
                min-height: 67px;
            }
        }
    }


    /***/

    .goals-wrap {
        .statistic-block {
            padding: 0;

            &--item {
                flex: auto;
            }

            .text {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    .goals-list {
        padding-left: 0;

        &--item {
            flex-direction: column;
            align-items: flex-start;
        }

        &--text {
            padding: 40px 0 0;
            font-size: 18px;
            line-height: 24px;
        }
    }


    /***/

    .benefits {
        &-wrap {
            .title {
                text-align: left;
            }
        }

        &-list {
            flex-direction: column;
            align-items: flex-start;

            &--item {
                display: flex;
                align-items: center;
                margin: 50px 0 0;

                &:first-child {
                    margin-top: 0;
                }
                &:nth-child(2) {
                    margin-top: 50px;
                }
            }

            &--image {
                flex: 1;
                max-width: 150px;
            }

            &--text {
                flex: 1;
                margin: 0 0 0 60px;
                text-align: left;
            }
        }
    }


    /***/

    .services-block {
        &--item {
            flex: 100%;
            min-height: 170px;
        }
        .image {
            max-width: 260px;
        }
    }


    /***/

    .info-user {
        margin-top: -40px;

        &-wrap {
            margin-top: 40px;

            &.short {
                .info-user {
                    margin-top: -40px;

                    &--item {
                        padding-top: 40px;
                    }
                }
            }
        }

        &--item {
            flex: 50%;
            max-width: 50%;
            padding-top: 40px;
        }

        &--text {
            margin-top: 12px;
        }
    }


    /***/

    .comment-list {
        &--item {
            display: block;
            margin-top: 20px;
            padding: 25px;
        }
        &--text {
            padding: 0;
        }
    }


    /***/

    .entries-list {
        .head {
            .col {
                .btn {
                    width: auto;
                    padding: 7px 30px;
                    font-size: 13px;
                }
            }
        }
        .body {
            display: block;
            margin: 30px 0 0;
            padding: 0;
            border: 0;
        }

        &--item {
            padding: 20px;
        }

        &--text {
            padding: 0;
            font-size: 14px;
            line-height: 16px;
        }
        .button-wrap {
            padding: 0;
        }
    }


    /***/

    .user-card {
        .description {
            margin-top: 60px;
        }
    }


    /***/

    .authorize-block {
        display: block;
        padding: 30px 0 0 30px;

        .button-wrap {
            display: block;
            margin-left: -20px;
            padding-left: 40px;

            .btn {
                margin-left: 20px;
            }
        }
    }


    /***/

    .comments-block-wrap {
        margin-top: 60px;
    }
    .comments-block {
        .name-wrap {
            padding-left: 30px;

            .letter {
                min-width: 47px;
            }
        }
    }
    .comment-field {
        margin-top: 40px;
        padding: 1px 1px 100px;

        &:before {
            left: 44px;
        }
        &:after {
            left: 45px;
            border-width: 0 25px 22px 0;
        }

        textarea {
            min-height: 160px;
            padding: 30px 20px;
            font-size: 18px;
            line-height: 28px;
        }

        .btn {
            right: 20px;
            padding: 12px 35px;
            font-size: 15px;
            line-height: 16px;
        }
    }


    /***/

    .reviews-block {
        margin-top: 50px;

        .controls {
            font-size: 20px;
            line-height: 20px;

            .sort {
                .item {
                    margin-left: 40px;
                }
            }
        }
    }

    .reviews-list {
        margin-top: 60px;

        &--item {
            flex-direction: column;
        }

        .name-wrap {
            display: flex;
            align-items: center;
            flex: none;
            max-width: none;
            padding: 0;


            .letter {
                min-width: 47px;
            }

            .name {
                flex: 1;
                max-width: 200px;
                margin: 0;
                padding: 0 0 0 20px;
            }
        }

        .text {
            margin-top: 30px;
            padding: 25px;
            font-size: 14px;
            line-height: 18px;
        }
    }


    /***/

    .authorize-page {
        .form {
            .btn {
                width: 100%;
            }
        }
    }
}

/******************************************************************/

@media (max-width: 561px) {
    p {
        font-size: 14px;
        line-height: 20px;
    }

    h2 {
        font-size: 40px;
        line-height: 44px;
    }

    .btn {
        padding: 20px 30px;
        font-size: 16px;
    }

    .section {
        margin: 20px 0;
    }


    .tabs {
        &-head {
            &-item,
            .delimiter {
                font-size: 30px;
                line-height: 34px;
            }
        }
        &-body {
            margin-top: 30px;
        }
    }

    .form {
        margin-top: 40px;

        .choice-row {
            .btn {
                font-size: 16px;
            }
            .delimiter {
                padding: 30px 0;
                font-size: 16px;
            }
            .link {
                font-size: 22px;

                img {
                    max-height: 25px;
                }
                .text {
                    padding-bottom: 4px;
                }
            }
        }

        .forgot {
            margin-left: 20px;
            font-size: 16px;
        }

        .statistic-row {
            .btn {
                width: 100%;
                flex: none;
                max-width: none;
            }
        }
    }

    .select-wrap {
        border-radius: 5px;

        &:after {
            width: 40px;
        }
        &:before {
            right: 20px;
            border-width: 6px 5px 0;
        }
    }
    .select {
        height: 58px;
        padding: 19px 40px 19px 20px;
        font-size: 16px;
    }

    .input {
        padding: 19px 20px;
        font-size: 16px;
    }

    .search-wrap {
        input {
            padding: 20px 50px 20px 20px;
            font-size: 16px;
        }
        .icon {
            max-width: 20px;
        }
        .button {
            right: 20px;
        }
    }


    .number-list{
        &--item {
            margin-top: 40px;
            padding: 60px 0 0;
            font-size: 16px;

            &:before {
                font-size: 45px;
            }
        }
    }


    /***/

    .first-block {
        h1 {
            font-size: 40px;
            line-height: 40px;
        }
        h2 {
            font-size: 26px;
            line-height: 32px;
        }
        .buttons {
            .btn,
            .search-wrap {
                min-height: 0;
            }
        }
    }


    /***/

    .goals-wrap {
        margin-top: 60px;

        .statistic-block {
            margin: 0;

            &-wrap {
                margin-top: 60px;
            }

            &--item {
                margin: 40px 0 0;

                &:first-child {
                    margin-top: 0;
                }
            }

            .text {
                font-size: 16px;
            }
        }
    }
    .goals {
        .image {
            max-width: 100px;
        }
        .text {
            padding-left: 20px;

            p {
                max-width: 700px;
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
    .goals-list {
        flex-direction: column;
        margin-left: 0;

        &--item {
            padding: 40px 0 0;

            &:first-child {
                padding-top: 0;
            }
        }

        &--text {
            padding: 20px 0 0;
            font-size: 16px;
        }
    }


    /***/

    .benefits-wrap {
        margin: 60px 0 0;
    }
    .benefits-list {
        &--item {
            margin: 20px 0 0;

            &:nth-child(2) {
                margin: 20px 0 0;
            }
        }
        &--image {
            max-width: 100px;
        }
        &--text {
            margin: 0 0 0 20px;
            font-size: 16px;
            line-height: 20px;
        }
    }


    /***/

    .services-block {
        margin: 0;

        &-wrap {
            margin-top: 60px;
        }

        &--item {
            min-height: 0;
            margin: 20px 0 0;

            &:first-child {
                margin: 0;
            }
        }
        .text {
            padding: 40px 20px;
            font-size: 18px;
            line-height: 25px;
        }
    }


    /***/

    .statistic-block {
        flex-direction: column;
        margin: 0;

        &-wrap {
            margin-top: 60px;
        }

        &--item {
            margin: 40px 0 0;

            &:first-child {
                margin: 0;
            }
        }

        .num {
            font-size: 64px;
            line-height: 64px;
        }

        .text {
            margin-top: 20px;
            font-size: 16px;
            line-height: 20px;
        }
    }


    /***/

    .info-user {
        &--item {
            flex: 100%;
            max-width: 100%;
            padding-right: 0;
        }
    }


    /***/

    .entries-list-wrap {
        margin-top: 50px;

        .entries-list {
            margin-top: 20px;
        }
    }

    .entries-list {
        .head {
            margin: -20px 0 0 0;

            .col {
                flex: 50%;
                max-width: 50%;
                padding: 20px 20px 0 0;
            }
        }
    }


    /***/

    .about-block {
        .title-wrap {
            margin-bottom: 30px;
        }
    }


    /***/

    .authorize-block {
        padding-left: 0;

        .button-wrap {
            padding-left: 0;
        }
    }


    /***/

    .reviews-block {
        .controls {
            flex-direction: column;
            align-items: flex-start;

            .sort {
                margin-top: 20px;
            }
        }
    }


    /***/

    .comment-field {
        textarea {
            font-size: 14px;
            line-height: 22px;
        }
    }


    /***/

    .authorize-page {
        .tabs {
            &-head {
                &-item,
                .delimiter {
                    font-size: 32px;
                    line-height: 32px;
                }
            }
        }
    }
}

@media (max-width: 426px) {
    h1,
    h2 {
        font-size: 32px;
        line-height: 32px;
    }
    h4 {
        font-size: 25px;
        line-height: 28px;
    }

    .button-wrap {
        flex-direction: column;
        align-items: flex-start;

        .remove {
            margin-top: 20px;
        }
    }

    .first-block {
        padding: 130px 0 0;

        h1 {
            font-size: 32px;
            line-height: 32px;
        }
    }

    .goals {
        flex-direction: column;

        .text {
            padding: 20px 0 0;
        }
    }

    .benefits-list {
        &--item {
            flex-direction: column;
            align-items: flex-start;
        }

        &--text {
            margin: 20px 0 0;
        }
    }


    /***/

    .entries-list {
        .head {
            .col {
                flex: 100%;
                max-width: 100%;
            }
        }
    }


    /***/

    .authorize-block {
        .button-wrap {
            margin: 0;

            .btn {
                width: 100%;
                margin: 15px 0 0;

                &:first-child {
                    margin: 0;
                }
            }
        }
    }

}