.container-block {
	position: relative;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
    max-width: 1340px;
	padding-left: 60px;
	padding-right: 60px;
}

.tight-block {
	position: relative;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1034px;
	padding-left: 40px;
	padding-right: 40px;
}

.press-right {
	padding-left: 195px;
}
.press-left {
	padding-right: 195px;
}

.display-block {
	display: block;
}
.display-inline-block {
	display: inline-block;
}
.display-inline {
	display: inline;
}

.display-none {
	display: none;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.align-start {
	display: flex;
	align-items: flex-start;
}
.align-end {
	display: flex;
	align-items: flex-end;
}
.align-center {
	display: flex;
	align-items: center;
}
.align-stretch {
	display: flex;
	align-items: stretch;
}
.align-center-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.flex-nowrap {
	flex-wrap: nowrap;
}
.flex-end {
	display: flex;
	justify-content: flex-end;
}
.flex-start {
	display: flex;
	justify-content: flex-start;
}
.flex-between {
	display: flex;
	justify-content: space-between;
}

.text-center {
	text-align: center;
	justify-content: center;
}

.overflow-hidden {
	overflow: hidden;
}

.w-10 {
	width: 10% !important;
}
.w-20 {
	width: 20% !important;
}
.w-25 {
	width: 25% !important;
}
.w-30 {
	width: 30% !important;
}
.w-38 {
	width: 38% !important;
}
.w-40 {
	width: 40% !important;
}
.w-50 {
	width: 50% !important;
}
.w-60 {
	width: 60% !important;
}
.w-70 {
	width: 70% !important;
}
.w-80 {
	width: 80% !important;
}
.w-90 {
	width: 90% !important;
}
.w-100 {
	width: 100% !important;
}

.mt-0 {
	margin-top: 0 !important;
}
.mt-10 {
	margin-top: 10px;
}
.mt-20 {
	margin-top: 20px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-40 {
	margin-top: 40px;
}
.mt-50 {
	margin-top: 50px;
}
.mt-60 {
	margin-top: 60px;
}
.mt-70 {
	margin-top: 70px;
}
.mt-80 {
	margin-top: 80px;
}
.mb-80 {
	margin-bottom: 80px;
}
.mt-90 {
	margin-top: 90px;
}
.mb-90 {
	margin-bottom: 90px;
}
.mt-45 {
	margin-top: 45px;
}
.mt-100 {
	margin-top: 100px;
}
.mb-100 {
	margin-bottom: 100px;
}
.mt-110 {
	margin-top: 110px;
}
.mb-110 {
	margin-bottom: 110px;
}
.mt-120 {
	margin-top: 120px;
}
.mt-125 {
	margin-top: 125px;
}
.mt-135 {
	margin-top: 135px;
}
.mt-130 {
	margin-top: 130px;
}
.mt-140 {
	margin-top: 140px;
}
.mt-145 {
	margin-top: 145px;
}
.mt-150 {
	margin-top: 150px;
}
.mt-160 {
	margin-top: 160px;
}
.mt-165 {
	margin-top: 165px;
}
.mt-170 {
	margin-top: 170px;
}
.mt-175 {
	margin-top: 175px;
}
.mt-180 {
	margin-top: 180px;
}
.mt-190 {
	margin-top: 190px;
}
.mt-200 {
	margin-top: 200px;
}
.mt-220 {
	margin-top: 220px;
}
.mt-230 {
	margin-top: 230px;
}
.mt-250 {
	margin-top: 250px;
}
.mt-270 {
	margin-top: 270px;
}
.mt-300 {
	margin-top: 300px;
}
.mt-400 {
	margin-top: 400px;
}
.mt-410 {
	margin-top: 410px;
}
.mt-420 {
	margin-top: 420px;
}

.mt-n-100 {
	margin-top: -100px;
}
.mb-n-100 {
	margin-bottom: -100px;
}
.mt-n-130 {
	margin-top: -130px;
}
.mb-n-130 {
	margin-bottom: -130px;
}
.mt-n-170 {
	margin-top: -170px;
}
.mb-n-170 {
	margin-bottom: -170px;
}
.mt-n-200 {
	margin-top: -200px;
}
.mb-n-200 {
	margin-bottom: -200px;
}

.ml-n-20 {
	margin-left: -20px;
}
.mr-n-20 {
	margin-right: -20px;
}
.ml-n-40 {
	margin-left: -40px;
}
.mr-n-40 {
	margin-right: -40px;
}
.ml-n-50 {
	margin-left: -50px;
}
.mr-n-50 {
	margin-right: -50px;
}
.ml-n-150 {
	margin-left: -150px;
}
.mr-n-150 {
	margin-right: -150px;
}

.ml-p-5 {
	margin-left: 5%;
}
.ml-p-10 {
	margin-left: 10%;
}
.ml-p-20 {
	margin-left: 20%;
}
.ml-p-30 {
	margin-left: 30%;
}

.mr-p-5 {
	margin-right: 5%;
}
.mr-p-10 {
	margin-right: 10%;
}
.mr-p-20 {
	margin-right: 20%;
}
.mr-p-30 {
	margin-right: 30%;
}

.ml-50 {
	margin-left: 50px;
}
.ml-100 {
	margin-left: 100px;
}
.ml-140 {
	margin-left: 140px;
}
.ml-145 {
	margin-left: 145px;
}
.ml-165 {
	margin-left: 165px;
}
.ml-185 {
	margin-left: 185px;
}
.ml-200 {
	margin-left: 200px;
}
.ml-245 {
	margin-left: 245px;
}
.ml-300 {
	margin-left: 300px;
}

.mr-50 {
	margin-right: 50px;
}
.mr-100 {
	margin-right: 100px;
}
.mr-185 {
	margin-right: 185px;
}
.mr-200 {
	margin-right: 200px;
}
.mr-300 {
	margin-right: 300px;
}

.p-0 {
	padding: 0 !important;
}
.pl-0 {
	padding-left: 0 !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.pt-30 {
	padding-top: 30px;
}
.pb-30 {
	padding-bottom: 30px;
}
.pt-30 {
	padding-top: 30px;
}
.pb-30 {
	padding-bottom: 30px;
}
.pt-30 {
	padding-top: 30px;
}
.pb-30 {
	padding-bottom: 30px;
}
.pt-40 {
	padding-top: 40px;
}
.pb-40 {
	padding-bottom: 40px;
}
.pt-50 {
	padding-top: 50px;
}
.pb-50 {
	padding-bottom: 50px;
}
.pt-60 {
	padding-top: 60px;
}
.pb-60 {
	padding-bottom: 60px;
}
.pt-70 {
	padding-top: 70px;
}
.pb-70 {
	padding-bottom: 70px;
}
.pt-80 {
	padding-top: 80px;
}
.pb-80 {
	padding-bottom: 80px;
}
.pt-90 {
	padding-top: 90px;
}
.pb-90 {
	padding-bottom: 90px;
}
.pt-100 {
	padding-top: 100px;
}
.pb-100 {
	padding-bottom: 100px;
}
.pt-120 {
	padding-top: 120px;
}
.pb-120 {
	padding-bottom: 120px;
}
.pt-160 {
	padding-top: 160px;
}
.pb-160 {
	padding-bottom: 160px;
}

.pr-20 {
	padding-right: 20px;
}
.pl-20 {
	padding-left: 20px;
}
.pr-30 {
	padding-right: 30px;
}
.pl-30 {
	padding-left: 30px;
}
.pr-40 {
	padding-right: 40px;
}
.pl-40 {
	padding-left: 40px;
}
.pr-50 {
	padding-right: 50px;
}
.pl-50 {
	padding-left: 50px;
}
.pr-60 {
	padding-right: 60px;
}
.pl-60 {
	padding-left: 60px;
}
.pr-70 {
	padding-right: 70px;
}
.pl-70 {
	padding-left: 70px;
}
.pr-80 {
	padding-right: 80px;
}
.pl-80 {
	padding-left: 80px;
}
.pr-90 {
	padding-right: 90px;
}
.pl-90 {
	padding-left: 90px;
}
.pr-100 {
	padding-right: 100px;
}
.pl-100 {
	padding-left: 100px;
}
.pr-130 {
	padding-right: 130px;
}
.pl-130 {
	padding-left: 130px;
}
.pr-135 {
	padding-right: 135px;
}
.pl-135 {
	padding-left: 135px;
}

.max-940 {
	max-width: 940px !important;
	margin: 0 auto;
}
.max-740 {
	max-width: 740px !important;
}
.max-p-30 {
	max-width: 30%;
}
.max-p-40 {
	max-width: 40%;
}
.max-p-50 {
	max-width: 50%;
}
.max-p-60 {
	max-width: 60%;
}
.max-p-70 {
	max-width: 70%;
}
.max-p-80 {
	max-width: 80%;
}
.max-p-90 {
	max-width: 90%;
}
.max-p-100 {
	max-width: 100%;
}

/******************** Max 1280 ********************/

@media (max-width: 1600px) {

}


/******************** Max 1280 ********************/

@media (max-width: 1281px) {
	.display-block-d {
		display: block;
	}
	.display-none-d {
		display: none;
	}

	.content-block,
	.container-block {
		padding-left: 40px;
		padding-right: 40px;
	}
	
	.w-d-10 {
		width: 10% !important;
	}
	.w-d-20 {
		width: 20% !important;
	}
	.w-d-25 {
		width: 25% !important;
	}
	.w-d-30 {
		width: 30% !important;
	}
	.w-d-38 {
		width: 38% !important;
	}
	.w-d-40 {
		width: 40% !important;
	}
	.w-d-50 {
		width: 50% !important;
	}
	.w-d-60 {
		width: 60% !important;
	}
	.w-d-70 {
		width: 70% !important;
	}
	.w-d-80 {
		width: 80% !important;
	}
	.w-d-90 {
		width: 90% !important;
	}
	.w-d-100 {
		width: 100% !important;
	}

	.mb-d-n-200 {
		margin-bottom: -200px !important;
	}

	.mt-d-300 {
		margin-top: 300px !important;
	}
	.mt-d-200 {
		margin-top: 200px !important;
	}
	.mt-d-150 {
		margin-top: 150px !important;
	}
	.mt-d-140 {
		margin-top: 140px !important;
	}
	.mt-d-130 {
		margin-top: 130px !important;
	}
	.mt-d-120 {
		margin-top: 120px !important;
	}
	.mt-d-110 {
		margin-top: 110px !important;
	}
	.mt-d-100 {
		margin-top: 100px !important;
	}
	.mt-d-90 {
		margin-top: 90px !important;
	}
	.mt-d-80 {
		margin-top: 80px !important;
	}
	.mt-d-70 {
		margin-top: 70px !important;
	}
	.mt-d-60 {
		margin-top: 60px !important;
	}
	.mt-d-50 {
		margin-top: 50px !important;
	}
	.mt-d-45 {
		margin-top: 45px !important;
	}
	.mt-d-40 {
		margin-top: 40px !important;
	}
	.mb-d-40 {
		margin-bottom: 40px !important;
	}
	.mt-d-30 {
		margin-top: 30px !important;
	}
	.mb-d-30 {
		margin-bottom: 30px !important;
	}
	.mt-d-20 {
		margin-top: 20px !important;
	}
	.mb-d-20 {
		margin-bottom: 20px !important;
	}
	.mt-d-15 {
		margin-top: 15px !important;
	}
	.mb-d-15 {
		margin-bottom: 15px !important;
	}
	.mt-d-10 {
		margin-top: 10px !important;
	}
	.mb-n-d-20 {
		margin-bottom: -20px !important;
	}
	.mt-d-0 {
		margin-top: 0 !important;
	}
	.mb-d-0,
	.mb-n-d-0 {
		margin-bottom: 0 !important;
	}

	.ml-n-d-0 {
		margin-left: 0 !important;
	}

	.ml-d-0 {
		margin-left: 0 !important;
	}

	.ml-p-d-15 {
		margin-left: 15% !important;
	}
	.mr-p-d-15 {
		margin-right: 15% !important;
	}

	.ml-p-d-20 {
		margin-left: 20% !important;
	}

	.mr-p-d-20 {
		margin-right: 20% !important;
	}

	.ml-p-d-30 {
		margin-left: 30% !important;
	}

	.mr-p-d-30 {
		margin-right: 30% !important;
	}

	.ml-n-d-150 {
		margin-left: -150px;
	}

	.p-d-0 {
		padding: 0 !important;
	}

	.pt-d-15 {
		padding-top: 15px !important;
	}

	.pb-d-40 {
		padding-bottom: 40px !important;
	}
	.pt-d-60 {
		padding-top: 60px !important;
	}
	.pb-d-60 {
		padding-bottom: 60px !important;
	}

	.pr-d-0 {
		padding-right: 0 !important;
	}
	.pl-d-0 {
		padding-left: 0 !important;
	}
	.pl-d-8 {
		padding-left: 8px !important;
	}
	.pr-d-8 {
		padding-right: 8px !important;
	}
	.pl-d-15 {
		padding-left: 15px !important;
	}
	.pr-d-15 {
		padding-right: 15px !important;
	}
	.pl-d-30 {
		padding-left: 8px !important;
	}
	.pr-d-30 {
		padding-right: 30px !important;
	}
	.pl-d-30 {
		padding-left: 30px !important;
	}
	.pr-d-50 {
		padding-right: 50px !important;
	}
	.pl-d-50 {
		padding-left: 50px !important;
	}
}


/******************** Max 1024 ********************/

@media (max-width: 1025px) {

	/*** Element tablet ***/

	.text-m-center {
		text-align: center !important;
	}
}


/******************** Max 992 ********************/

@media (max-width: 993px) {

}


/******************** Max 768 ********************/

@media (max-width: 769px) {

	.desktop {
		display: none !important;
	}
	.mobile {
		display: block !important;
	}

	.initial-position-t {
		flex-direction: column;
	}

	.column-revert-t {
		display: flex;
		flex-direction: column-reverse;
	}

	.column-t {
		display: flex;
		flex-direction: column;
	}

	.display-block-t {
		display: block;
	}
	.display-none-t {
		display: none;
	}

	.w-t-30 {
		width: 30% !important;
	}
	.w-t-40 {
		width: 40% !important;
	}
	.w-t-50 {
		width: 50% !important;
	}
	.w-t-60 {
		width: 60% !important;
	}
	.w-t-70 {
		width: 70% !important;
	}
	.w-t-80 {
		width: 80% !important;
	}
	.w-t-90 {
		width: 90% !important;
	}
	.w-t-100 {
		width: 100% !important;
	}
	.max-p-t-10 {
		max-width: 10%;
	}
	.max-p-t-20 {
		max-width: 20%;
	}
	.max-p-t-30 {
		max-width: 30%;
	}
	.max-p-t-40 {
		max-width: 40%;
	}
	.max-p-t-50 {
		max-width: 50%;
	}
	.max-p-t-60 {
		max-width: 60%;
	}
	.max-p-t-70 {
		max-width: 70%;
	}
	.max-p-t-80 {
		max-width: 80%;
	}
	.max-p-t-90 {
		max-width: 90%;
	}
	.max-p-t-100 {
		max-width: 100% !important;
	}

	.mb-t-n-200 {
		margin-bottom: -200px !important;
	}

	.mt-t-300 {
		margin-top: 300px !important;
	}
	.mt-t-200 {
		margin-top: 200px !important;
	}
	.mt-t-150 {
		margin-top: 150px !important;
	}
	.mt-t-130 {
		margin-top: 130px !important;
	}
	.mt-t-100 {
		margin-top: 100px !important;
	}
	.mt-t-90 {
		margin-top: 90px !important;
	}
	.mt-t-80 {
		margin-top: 80px !important;
	}
	.mt-t-70 {
		margin-top: 70px !important;
	}
	.mt-t-60 {
		margin-top: 60px !important;
	}
	.mt-t-50 {
		margin-top: 50px !important;
	}
	.mt-t-45 {
		margin-top: 45px !important;
	}
	.mt-t-40 {
		margin-top: 40px !important;
	}
	.mb-t-40 {
		margin-bottom: 40px !important;
	}
	.mt-t-30 {
		margin-top: 30px !important;
	}
	.mb-t-30 {
		margin-bottom: 30px !important;
	}
	.mt-t-20 {
		margin-top: 20px !important;
	}
	.mb-t-20 {
		margin-bottom: 20px !important;
	}
	.mt-t-15 {
		margin-top: 15px !important;
	}
	.mb-t-15 {
		margin-bottom: 15px !important;
	}
	.mt-t-10 {
		margin-top: 10px !important;
	}
	.mb-n-t-20 {
		margin-bottom: -20px !important;
	}
	.mt-t-0 {
		margin-top: 0 !important;
	}
	.mb-t-0,
	.mb-n-t-0 {
		margin-bottom: 0 !important;
	}

	.ml-n-t-0 {
		margin-left: 0 !important;
	}

	.ml-t-0 {
		margin-left: 0 !important;
	}

	.ml-p-t-15 {
		margin-left: 15% !important;
	}
	.mr-p-t-15 {
		margin-right: 15% !important;
	}

	.ml-p-t-20 {
		margin-left: 20% !important;
	}

	.mr-p-t-20 {
		margin-right: 20% !important;
	}

	.ml-p-t-30 {
		margin-left: 30% !important;
	}

	.mr-p-t-30 {
		margin-right: 30% !important;
	}

	.ml-n-t-150 {
		margin-left: -150px;
	}

	.p-t-0 {
		padding: 0 !important;
	}

	.pt-t-15 {
		padding-top: 15px !important;
	}

	.pb-t-40 {
		padding-bottom: 40px !important;
	}
	.pt-t-60 {
		padding-top: 60px !important;
	}
	.pb-t-60 {
		padding-bottom: 60px !important;
	}

	.pr-t-0 {
		padding-right: 0 !important;
	}
	.pl-t-0 {
		padding-left: 0 !important;
	}
	.pl-t-8 {
		padding-left: 8px !important;
	}
	.pr-t-8 {
		padding-right: 8px !important;
	}
	.pl-t-15 {
		padding-left: 15px !important;
	}
	.pr-t-15 {
		padding-right: 15px !important;
	}
	.pl-t-30 {
		padding-left: 8px !important;
	}
	.pr-t-30 {
		padding-right: 30px !important;
	}
	.pl-t-30 {
		padding-left: 30px !important;
	}
	.pr-t-40 {
		padding-right: 40px !important;
	}
	.pl-t-40 {
		padding-left: 40px !important;
	}
	.pr-t-50 {
		padding-right: 50px !important;
	}
	.pl-t-50 {
		padding-left: 50px !important;
	}
}


/******************** Max 560 ********************/

@media (max-width: 561px) {
	.content-block,
	.container-block {
		padding-left: 20px;
		padding-right: 20px;
	}
	.tight-block {
		padding-left: 20px;
		padding-right: 20px;
	}
	.press-right {
		padding-left: 20px;
		padding-right: 20px;
	}
	.press-left {
		padding-right: 20px;
		padding-left: 20px;
	}


	/*** Element mobile ***/

	.text-m-center {
		text-align: center !important;
	}

	.display-block-m {
		display: block;
	}
	.display-none-m {
		display: none;
	}

	.w-m-30 {
		width: 30% !important;
	}

	.w-m-40 {
		width: 40% !important;
	}

	.w-m-50 {
		width: 50% !important;
	}

	.w-m-60 {
		width: 60% !important;
	}

	.w-m-70 {
		width: 70% !important;
	}

	.w-m-80 {
		width: 80% !important;
	}

	.w-m-90 {
		width: 90% !important;
	}

	.w-m-100 {
		width: 100% !important;
	}

	.max-p-m-10 {
		max-width: 10%;
	}

	.max-p-m-20 {
		max-width: 20%;
	}

	.max-p-m-30 {
		max-width: 30%;
	}

	.max-p-m-40 {
		max-width: 40%;
	}

	.max-p-m-50 {
		max-width: 50%;
	}

	.max-p-m-60 {
		max-width: 60%;
	}

	.max-p-m-70 {
		max-width: 70%;
	}

	.max-p-m-80 {
		max-width: 80%;
	}

	.max-p-m-90 {
		max-width: 90%;
	}

	.max-p-m-100 {
		max-width: 100%;
	}

	.mb-m-n-200 {
		margin-bottom: -200px !important;
	}

	.mt-m-300 {
		margin-top: 300px !important;
	}

	.mt-m-200 {
		margin-top: 200px !important;
	}

	.mt-m-150 {
		margin-top: 150px !important;
	}

	.mt-m-130 {
		margin-top: 130px !important;
	}

	.mt-m-100 {
		margin-top: 100px !important;
	}

	.mt-m-90 {
		margin-top: 90px !important;
	}

	.mt-m-80 {
		margin-top: 80px !important;
	}

	.mt-m-70 {
		margin-top: 70px !important;
	}

	.mt-m-60 {
		margin-top: 60px !important;
	}

	.mt-m-50 {
		margin-top: 50px !important;
	}

	.mt-m-45 {
		margin-top: 45px !important;
	}

	.mt-m-40 {
		margin-top: 40px !important;
	}

	.mb-m-40 {
		margin-bottom: 40px !important;
	}

	.mt-m-30 {
		margin-top: 30px !important;
	}

	.mb-m-30 {
		margin-bottom: 30px !important;
	}

	.mt-m-20 {
		margin-top: 20px !important;
	}

	.mb-m-20 {
		margin-bottom: 20px !important;
	}

	.mt-m-15 {
		margin-top: 15px !important;
	}

	.mb-m-15 {
		margin-bottom: 15px !important;
	}

	.mt-m-10 {
		margin-top: 10px !important;
	}

	.mb-n-m-20 {
		margin-bottom: -20px !important;
	}

	.mt-m-0 {
		margin-top: 0 !important;
	}

	.mb-m-0,
	.mb-n-m-0 {
		margin-bottom: 0 !important;
	}

	.ml-n-m-0 {
		margin-left: 0 !important;
	}

	.ml-m-0 {
		margin-left: 0 !important;
	}

	.ml-p-m-15 {
		margin-left: 15% !important;
	}

	.mr-p-m-15 {
		margin-right: 15% !important;
	}

	.ml-p-m-20 {
		margin-left: 20% !important;
	}

	.mr-p-m-20 {
		margin-right: 20% !important;
	}

	.ml-p-m-30 {
		margin-left: 30% !important;
	}

	.mr-p-m-30 {
		margin-right: 30% !important;
	}

	.ml-n-150 {
		margin-left: -150px;
	}

	.p-m-0 {
		padding: 0 !important;
	}

	.pt-m-15 {
		padding-top: 15px !important;
	}

	.pb-m-40 {
		padding-bottom: 40px !important;
	}

	.pt-m-60 {
		padding-top: 60px !important;
	}
	.pb-m-60 {
		padding-bottom: 60px !important;
	}

	.pr-m-0 {
		padding-right: 0 !important;
	}

	.pl-m-0 {
		padding-left: 0 !important;
	}

	.pl-m-8 {
		padding-left: 8px !important;
	}

	.pr-m-8 {
		padding-right: 8px !important;
	}

	.pl-m-15 {
		padding-left: 15px !important;
	}
	.pr-m-15 {
		padding-right: 15px !important;
	}

	.pl-m-20 {
		padding-left: 20px !important;
	}
	.pr-m-20 {
		padding-right: 20px !important;
	}

	.pl-m-30 {
		padding-left: 30px !important;
	}
	.pr-m-30 {
		padding-right: 30px !important;
	}

	.pl-m-40 {
		padding-left: 40px !important;
	}
	.pr-m-40 {
		padding-right: 40px !important;
	}

	.pr-m-50 {
		padding-right: 50px !important;
	}
	.pl-m-50 {
		padding-left: 50px !important;
	}
}


/******************** Max 460 ********************/

@media (max-width: 461px) {
	.content-block,
	.container-block {
		padding-left: 20px;
		padding-right: 20px;
	}
	.tight-block {
		padding-left: 20px;
		padding-right: 20px;
	}
	.press-right {
		padding-left: 20px;
		padding-right: 20px;
	}
	.press-left {
		padding-right: 20px;
		padding-left: 20px;
	}
}