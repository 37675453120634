.wrapper {
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100vh - 554px);
    padding: 0 0 75px;
}

.first-block {
    position: relative;
    width: 100%;
    min-height: 939px;
    margin-bottom: 75px;
    padding: 210px 0 60px;
    background-size: cover;
    background-position: center;
    z-index: 5;

    .mobile-bg {
        display: none;
        margin-top: -130px;

        img {
            width: 100%;
            max-height: 556px;
            object-fit: cover;
        }
    }

    h1 {
        margin-top: 30px;
        font-family: $proximaNova;
        color: $black;
        font-size: 81px;
        line-height: 70px;
        text-transform: none;
    }
    h2 {
        max-width: 830px;
        margin-top: 20px;
        font-family: $proximaNovaLight;
        color: $black;
        font-size: 50px;
        line-height: 47px;
        text-transform: none;
    }
    p {
        max-width: 660px;
        margin-top: 20px;
        font-family: $helvetica;
        color: $black;
        font-size: 20px;
        line-height: 28px;
        opacity: 0.7;
    }
    .buttons {
        display: flex;
        margin-top: 50px;

        .btn {
            width: auto;
        }

        .search-wrap {
            margin-left: 20px;
        }
    }
}

.section {
    width: 100%;
    margin: 75px 0;
}

.title-wrap {
    .title {
        margin-top: 40px;

        &:first-child {
            margin-top: 0;
        }
    }

    p {
        margin-top: 30px;
    }
}
.sup-title {
    display: inline-block;
    padding: 7px 10px;
    background: $white;
    font-family: $proximaNovaBold;
    color: $black;
    font-size: 12px;
    letter-spacing: 5px;
    text-transform: uppercase;
    border: 1px solid $borderColor;
    border-radius: 5px;
}


/***/

.goals-wrap {
    margin-top: 70px;

    &:first-child {
        margin-top: 0;
    }

    .statistic-block {
        max-width: 1300px;
        margin-left: -60px;
        padding-left: 290px;

        &-wrap {
            margin-top: 140px;
        }

        &--item {
            flex-direction: row;
            margin-left: 60px;
        }

        .num-wrap {
            .icon {
                img {
                    max-width: 26px;
                }
            }
        }
        .num {
            font-size: 77px;
            line-height: 72px;
        }
        .text {
            margin: 0;
            padding-left: 20px;
            font-size: 22px;
            line-height: 27px;
        }
    }
}
.goals {
    display: flex;

    .image {
        flex: 1;
        max-width: 220px;
    }
    .text {
        max-width: 1000px;
        flex: 1;
        padding-left: 50px;
        font-size: 34px;
        line-height: 38px;

        p {
            max-width: 700px;
            color: $black;
            font-size: 26px;
            line-height: 35px;
            opacity: 0.7;
        }
    }
}
.goals-list {
    display: flex;
    max-width: 1300px;
    margin: 60px 0 0 -30px;
    padding-left: 290px;

    &--item {
        display: flex;
        padding-left: 30px;
    }

    &--icon {
        flex: 1;
        max-width: 70px;
        text-align: center;
    }
    &--text {
        flex: 1;
        padding-left: 30px;
        font-size: 18px;
        line-height: 25px;
        color: $black;
        opacity: 0.7;

        p {
            color: $black;
            font-size: 20px;
            line-height: 25px;
            opacity: 0.7;
        }
    }
}

.benefits-wrap {
    margin: 130px 0 0;
    text-align: center;

    &:first-child {
        margin-top: 0;
    }

    .title {
        max-width: 720px;
        margin: 0 auto;
    }
}
.benefits-list {
    display: flex;
    justify-content: center;
    margin: 70px 0 0 0;

    &--item {
        margin: 0 35px;

        &:nth-child(2) {
            margin-top: 130px;
        }
    }

    &--image {
        img {
            margin: 0 auto;
        }
    }
    &--text {
        margin-top: 50px;
        font-size: 18px;
        line-height: 26px;
        opacity: 0.7;
    }
}


/***/

.services-block {
    display: flex;
    flex-wrap: wrap;
    margin: -40px 0 0 -40px;

    &-wrap {
        margin-top: 117px;

        &:first-child {
            margin-top: 0;
        }
    }

    &--item {
        display: flex;
        flex: 1;
        margin: 40px 0 0 40px;
        background: $white;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 20px $shadow;
    }

    .image {
        position: relative;
        flex: 1;
        max-width: 360px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right;
        }
    }
    .text {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 50px 40px 50px 30px;
        font-family: $proximaNova;
        font-size: 25px;
        line-height: 30px;
        color: $black;
    }
}


/***/

.statistic-block {
    display: flex;
    margin: 0 0 0 -130px;

    &-wrap {
        margin-top: 140px;
    }

    &--item {
        display: flex;
        flex-direction: column;
        flex: auto;
        max-width: 560px;
        align-items: flex-start;
        margin: 0 0 0 130px;

        &:first-child {
            .num-wrap {
                align-items: flex-start;

                .num {
                    margin-right: 0;
                }
                .icon {
                    margin-top: -20px;
                }
            }
        }
    }

    .num-wrap {
        position: relative;
        display: flex;
        align-items: baseline;
    }
    .num {
        margin-right: 20px;
        font-family: $proximaNovaSemiBold;
        color: $black;
        font-size: 90px;
        line-height: 70px;
    }
    .text {
        flex: 1;
        margin-top: 40px;
        font-size: 18px;
        line-height: 26px;
        color: $black;
        opacity: 0.7;
    }
}


/***/

.info-user {
    display: flex;
    flex-wrap: wrap;
    margin: -70px 0 0 0;

    &-wrap {
        margin-top: 70px;

        &.short {
            .info-user {
                &--item {
                    padding: 70px 80px 0 0;

                    &.car {
                        .info-user--text {
                            font-family: $helvetica;
                            font-weight: normal;
                        }
                    }
                    &.category {
                        .info-user--text {
                            font-family: $helveticaBold;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    &--item {
        flex: 25%;
        max-width: 25%;
        padding: 70px 0 0 80px;
    }
    &--label {
        font-size: 12px;
        color: $grayDim;
        font-family: $helveticaBold;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    &--text {
        margin-top: 20px;
        color: $black;
        font-family: $helveticaMedium;
        font-size: 20px;
        line-height: 23px;
        font-weight: bold;
        opacity: 0.84;

        a {
            color: $black;
        }
    }

    a {
        color: $black;
    }

    .name {
        position: relative;
        max-width: 250px;

        .letter {
            position: absolute;
            top: -5px;
            left: -70px;
        }
        .text {
            font-size: 20px;
            line-height: 21px;
            font-weight: bold;
            text-transform: uppercase;
            opacity: 0.7;
        }
    }
}

.letter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: $grayDim;
    color: $darkGrayOther;
    font-family: $helveticaBold;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
}


/***/

.comment-list {
    &--item {
        display: flex;
        margin-top: 30px;
        padding: 40px 50px;
        background: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px $shadow;

        &:first-child {
            margin-top: 0;
        }
    }

    &--text {
        flex: 1;
        padding-right: 40px;
        font-size: 18px;
        line-height: 28px;
        opacity: 0.7;
    }
}


/***/

.entries-list-wrap {
    margin-top: 60px;

    .entries-list {
        margin-top: 50px;

        &:first-child {
            margin-top: 0;
        }
    }
}

.entries-list-label {
    display: flex;
    align-items: center;
    margin: 60px 0 0 -40px;
    padding: 0 40px;

    &:first-child {
        margin-top: 0;
    }

    .col {
        flex: 1;
        padding: 0 0 0 40px;
        font-family: $helveticaBold;
        font-size: 12px;
        line-height: 21px;
        color: $black;
        text-transform: uppercase;
        opacity: 0.5;
    }
}

.entries-list {
    &--item {
        margin-top: 20px;
        padding: 40px;
        background: $white;
        border-radius: 10px;
        box-shadow: 0 0 20px $shadow;

        &:first-child {
            margin-top: 0;
        }
    }

    .head {
        display: flex;
        align-items: center;
        margin-left: -40px;

        .col {
            flex: 1;
            padding: 0 0 0 40px;
            font-family: $helvetica;
            font-size: 16px;
            line-height: 18px;
            font-weight: bold;
            color: $black;
            opacity: 0.8;

            &.name {
                font-family: $helveticaBold;
                text-transform: uppercase;
            }
            &.violation {
                font-family: $helveticaBold;
                text-transform: uppercase;
            }
            &.car {
                font-family: $helvetica;
                font-weight: normal;
            }
            &.date {
                font-family: $helveticaBold;
            }
            &.button {
                display: none;
            }
        }
    }
    .body {
        display: flex;
        margin: 40px 0 0;
        padding: 40px 0 0;
        border-top: 1px solid $grayLight;
    }
    &--text {
        flex: 1;
        padding-right: 40px;
        color: $darkGray;
        font-size: 16px;
        line-height: 22px;
        opacity: 0.8;
    }
    .button-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        max-width: calc(20% + 5px);
        padding: 0 0 0 40px;

        .btn,
        .remove {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }
        }

        .remove {
            color: $red;
        }
    }
}


/***/

.about-block {
    .title-wrap {
        margin-bottom: 50px;
    }
}


/***/

.user-card {
    .description {
        margin-top: 80px;

        p {
            margin-top: 20px;
        }
    }
}

.comments-block-wrap {
    margin-top: 120px;
}

.authorize-block {
    display: flex;
    padding: 50px 0 0 50px;

    .text-wrap {
        display: flex;
        align-items: center;
        flex: 1;

        .icon {
            flex: 1;
            max-width: 35px;
        }

        .text {
            flex: 1;
            padding: 0 0 0 30px;
            color: $textGray;
            font-size: 16px;
            line-height: 22px;
        }
    }
    .button-wrap {
        flex-direction: row;
        max-width: none;

        .btn {
            width: auto;
            margin: 0 0 0 15px;
            padding: 10px 40px;
            font-size: 14px;
        }
    }
}

.comments-block {
    margin-top: 60px;

    .name-wrap {
        display: flex;
        padding-left: 50px;

        .name {
            max-width: 230px;
            padding-left: 30px;
            color: $textGray;
            font-family: $helveticaBold;
            font-size: 20px;
            line-height: 21px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.comment-field {
    position: relative;
    width: 100%;
    margin-top: 55px;
    padding: 1px 290px 1px 1px;
    background: $white;
    border: 1px solid $borderColor;
    border-radius: 5px;

    &:before {
        content: '';
        position: absolute;
        top: -23px;
        left: 69px;
        border-style: solid;
        border-width: 0 27px 23px 0;
        border-color: transparent transparent $borderColor;
        border-radius: 5px 0 0 0;
    }
    &:after {
        content: '';
        position: absolute;
        top: -21px;
        left: 70px;
        border-style: solid;
        border-width: 0 26px 22px 0;
        border-color: transparent transparent $white;
        border-radius: 3px 0 0 0;
    }

    textarea {
        width: 100%;
        min-height: 300px;
        padding: 30px 40px;
        font-family: $helvetica;
        font-size: 16px;
        border: 0;
        border-radius: 10px;

        &::placeholder {
            color: $darkGrayOpacity;
        }
    }

    .btn {
        position: absolute;
        right: 40px;
        bottom: 30px;
        padding: 15px 40px;
        font-size: 20px;
        line-height: 21px;
    }
}


/***/

.reviews-block {
    margin-top: 80px;

    .controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: $proximaNovaLight;
        font-size: 24px;
        line-height: 20px;

        .sort {
            display: flex;

            .item {
                margin-left: 70px;
                padding: 0 0 8px;
                border-bottom: 1px solid;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

.reviews-list {
    margin-top: 70px;

    &--item {
        display: flex;
        margin-top: 45px;

        &:first-child {
            margin-top: 0;
        }
    }

    .name-wrap {
        flex: 1;
        max-width: 200px;
        padding: 10px 40px 0 0;

        .name {
            margin-top: 20px;
            color: $textGray;
            font-family: $helveticaBold;
            font-size: 18px;
            line-height: 20px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .text {
        flex: 1;
        padding: 40px;
        color: $textGray;
        font-size: 16px;
        line-height: 26px;
        border-radius: 5px;
        box-shadow: 0 0 20px $shadow;
    }
}


/***/

.authorize-page {
    .tabs {
        &-head {
            &-item,
            .delimiter {
                font-size: 60px;
                line-height: 58px;
            }
        }
    }

    .form {
        .btn {
            width: 100%;
            max-width: 430px;
        }
    }
}

.login-form {
    max-width: 950px;
}