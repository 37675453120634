.footer {
	position: relative;
    width: 100%;
    background: url("../img/footer-fon.jpg");
    background-size: cover;
    color: $white;
    font-size: 14px;
    line-height: normal;

    .footer-wrap {
        display: flex;
        padding: 100px 0;

        .left-block {
            position: relative;
            flex: 1;
            max-width: 340px;
            padding-bottom: 80px;

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 45px;
                height: 4px;
                background: $white;
            }

            p {
                max-width: 146px;
                margin-top: 50px;
                color: $white;
                font-size: 14px;
                line-height: normal;
                opacity: 0.7;
            }
        }
        .right-block {
            flex: 1;
            padding-left: 60px;
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin-top: 65px;

        &:first-child {
            margin-top: 0;
        }
    }

    .logo {
        font-family: $proximaNovaExtraBold;
        font-size: 30px;
        color: $white;
    }

    .btn {
        padding: 21px 30px;
        font-size: 16px;
    }

    .site-name {
        max-width: 146px;
        margin-top: 10px;
        font-family: $proximaNova;
        font-size: 18px;
        line-height: 18px;
    }

    .menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: -20px 0 0 -40px;

        &--item {
            display: flex;
            align-items: center;
            margin: 20px 0 0 20px;
            font-size: 17px;
            color: $white;

            .icon {
                margin-right: 10px;
            }
        }
    }

    .advertising {
        display: flex;
        flex-wrap: wrap;
        margin: -20px 0 0 -20px;

        &-wrap {
            flex: 1;
            margin-left: 50px;
        }

        &--item {
            display: flex;
            align-items: stretch;
            flex: 1;
            margin: 20px 0 0 20px;
            background: $white;
            color: $textColor;
            border-radius: 5px;
            overflow: hidden;

            &:first-child {
                max-width: 250px;
            }
        }

        .image {
            position: relative;
            flex: 1;
            max-width: 200px;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: right;
            }
        }
        .text {
            display: flex;
            align-items: center;
            max-width: 200px;
            flex: 1;
            padding: 20px 20px 20px 10px;
            font-size: 12px;
            font-family: $proximaNova;
        }
    }

    .btn {
        width: auto;
    }

    .search-wrap {
        flex: 1;
        margin-left: 40px;
        box-shadow: none;

        input {
            padding: 21px 80px 21px 30px;
            font-size: 16px;
        }

        .button {
            max-width: 22px;
            right: 30px;
        }
    }

    .link-list {
        display: flex;
        align-items: center;
        margin: -20px 0 0 -70px;

        &--item {
            margin: 20px 0 0 70px;
            font-size: 22px;
            color: $white;

            .icon {
                margin-right: 20px;
            }

            .text {
                padding-bottom: 8px;
                border-bottom: 1px solid $borderColor;
            }
        }
    }
}


@media (max-width: 1441px) {
    .footer {
        .footer-wrap {
            .left-block {
                max-width: 260px;
            }
        }
    }
}

@media (max-width: 1281px) {
    .footer {
        .row {
            margin-top: 40px;
        }

        .link-list {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-end;
            margin: 0 0 0 auto;

            &--item {
                margin: 30px 0 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

@media (max-width: 1025px) {
    .footer {
        .row {
            &:nth-child(1) {
                flex-direction: column;
            }
            &:nth-child(2) {
                flex-direction: column-reverse;
            }
        }

        .advertising-wrap {
            margin: 40px 0 0;
        }

        .btn {
            margin: 20px 0 0;
        }

        .search-wrap {
            flex: auto;
            margin: 0;
        }
    }
}

@media (max-width: 769px) {
    .footer {
        .footer-wrap {
            flex-direction: column-reverse;
            padding: 90px 0;

            .left-block {
                display: flex;
                justify-content: space-between;
                max-width: none;
                padding: 70px 0 0;

                &:before {
                    display: none;
                }

                p {
                    margin: 0;
                }
            }

            .right-block {
                display: flex;
                flex-wrap: wrap;
                max-width: 700px;
                padding: 0;
            }
        }

        .row {
            flex: 100%;

            &:nth-child(1) {
                display: block;
                margin: 60px 0 0;
                order: 2;
            }
            &:nth-child(2) {
                margin: 0;
                order: 1;
            }
            &:nth-child(3) {
                margin: 60px 0 0;
                order: 3;
            }
        }

        .link-list {
            align-items: flex-start;
            margin-left: 0;

            &--item {
                font-size: 18px;

                .icon {
                    display: inline-block;
                    max-width: 20px;
                }
            }
        }

        .menu {
            justify-content: flex-start;
            margin: -40px 0 0 -60px;

            &--item {
                margin: 40px 0 0 60px;
                font-size: 20px;
            }
        }

        .advertising-wrap {
            margin: 60px 0 0;
        }
        .advertising {
            align-items: stretch;
            flex-direction: column-reverse;

            &--item {
                align-items: stretch;

                &:first-child {
                    max-width: none;
                }
            }

            .image {
                display: flex;
                height: auto;
            }
            .text {
                padding-right: 20px;
            }
        }
    }
}

@media (max-width: 561px) {
    .footer {
        .footer-wrap {
            padding: 60px 0;

            .left-block {
                flex-direction: column;
                flex: auto;

                p {
                    max-width: none;
                    margin-top: 20px;
                }
            }
            .right-block {
                flex: auto;
            }
        }

        .site-name {
            max-width: none;
            margin-top: 20px;
        }

        .advertising {
            display: block;
        }
    }
}