@import '_var.scss';


html, body { 
    height: 100%;
    width: 100%;
    margin: 0; 
    padding: 0;
}

* { 
    box-sizing: border-box;
    outline: none;
}

body {
    margin: 0 auto;
    font-size: 16px;
    font-family: $helvetica;
    color: $black;
    font-weight: normal;
    overflow-x: hidden;
}

.noscroll {
    overflow: hidden;
}

.mobile {
    display: none !important;
}

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
    margin: 0;
    color: $black;
    font-family: $proximaNovaLight;
    font-weight: normal;
}
h1, .h1 {
    font-size: 60px;
    line-height: 58px;
}
h2, .h2 {
    font-size: 60px;
    line-height: 58px;
}
h3, .h3 {
    font-size: 44px;
    line-height: 48px;
}
h4, .h4 {
    font-size: 30px;
    line-height: 38px;
}

a {
    color: $linkColor;
    text-decoration: none;
    outline: none;
    transition: 0.3s;

    &:hover {
        opacity: 0.7;
    }
}

p {
    margin: 30px 0 0;
    color: $black;
    font-size: 18px;
    line-height: 28px;
    font-weight: normal;
    opacity: 0.7;

    &:first-child {
        margin: 0;
    }

    a {
        text-decoration: underline;
    }
}

ul {
    padding: 0;
    margin: 20px 0 0;
    list-style: none;
}
ul li {
    position: relative;
    padding-left: 35px;
    padding-top: 2px;
    font-size: 16px;
    line-height: 25px;

    &:first-child {
        padding-top: 0;
    }

    &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 4px;
        height: 4px;
        background: $textColor;
        border-radius: 50%;
    }
}


.control-block {
    position: absolute;
    top: 0;
    left: 0;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 0 20px $shadow;

    &-wrap {
        position: sticky;
        display: flex;
        align-items: center;
        height: 240px;
        top: 300px;
        left: calc(100vw - 142px);
        z-index: 997;
    }

    &.hide {
        display: none;
    }

    &--item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        text-align: center;
        border-top: 1px solid $borderColor;

        &:first-child {
            border-top: 0;
        }
    }
}


.number-list {
    max-width: 1000px;
    list-style: none;
    counter-reset: counter;

    &--item {
        position: relative;
        margin-top: 40px;
        padding: 0 0 0 80px;
        counter-increment: counter;
        color: $darkGray;
        font-size: 20px;
        line-height: 28px;

        &:before {
            content: counter(counter) " /";
            position: absolute;
            top: 10px;
            left: 0;
            font-family: $proximaNova;
            color: $black;
            font-size: 45px;
        }

        &:first-child {
            margin-top: 0;
        }
    }
}

img {
    max-width: 100%;
}

.btn {
    position: relative;
    display: inline-block;
    padding: 22px 50px;
    background: $themeColor;
    border: 0;
    color: $white;
    font-size: 20px;
    font-family: $helvetica;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.2px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50px;

    &:hover {
        opacity: 0.7;
    }

    .icon {
        margin-right: 15px;
    }

    &.btn-white {
        background: $white;
        color: $themeColor;
        border-color: $white;

        .icon:before {
            color: $themeColor;
        }
    }

    &.btn-empty {
        background: none;
        color: $black;
        border: 1px solid $grayDim;

        .icon:before {
            color: $themeColor;
        }

        &.btn-white {
            .icon:before {
                color: $white;
            }
        }
    }

    &.btn-small {
        padding: 10px 40px;
        font-size: 14px;
    }
}

/*** Form ***/

form {
    margin: 0;
}

.form {
    margin-top: 70px;

    &:first-child {
        margin-top: 0;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin: 30px 0 0 -20px;

        &:first-child {
            margin-top: 0;
        }

        .row {
            flex: 100%;
            margin: 20px 0 0;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .col {
        flex: 1;
        max-width: calc(100% - 20px);
        margin-left: 20px;

        .right-block {
            padding-left: 10px;
        }

        .info-block {
            display: flex;
            align-items: flex-start;
            margin-top: 30px;

            .item {
                flex: 1;
                padding: 0 0 0 20px;
                font-size: 16px;
                line-height: 190.65%;

                span {
                    display: block;
                }
            }

            .btn {
                margin: 0;
            }
        }

        &.wide {
            flex: 100%;
        }
    }

    .button-wrap {
        display: flex;
        align-items: center;

        .delimiter {
            padding: 0 20px;
        }

        .link {
            display: flex;
            font-size: 18px;
            color: $textColor;
        }
    }

    .choice-row {
        display: flex;
        align-items: center;

        .btn {
            flex: 1;
            max-width: 610px;
        }

        .delimiter {
            padding: 0 40px;
            font-size: 20px;
            color: $darkGray;
            opacity: 0.84;
        }

        .link {
            display: flex;
            align-items: center;
            flex: 1;
            font-size: 25px;
            color: $black;

            .icon {
                margin-right: 20px;
            }

            .text {
                padding: 0 0 5px;
                border-bottom: 1px solid $borderColor;
            }
        }
    }

    .statistic-row {
        display: flex;
        align-items: center;

        .btn {
            flex: 1;
            max-width: 430px;
        }

        .statistic-block {
            max-width: 1300px;
            margin: 10px 0 0 -40px;
            padding-left: 30px;

            &-wrap {
                margin-top: 140px;
            }

            &--item {
                flex-direction: row;
                max-width: 380px;
                margin-left: 40px;
            }

            .num-wrap {
                .icon {
                    img {
                        max-width: 20px;
                    }
                }
            }
            .num {
                font-size: 55px;
                line-height: 52px;
            }
            .text {
                margin: 0;
                padding-left: 20px;
                font-size: 18px;
                line-height: 20px;
            }
        }
    }

    .forgot {
        margin-left: 40px;
        padding: 0 0 5px;
        font-size: 20px;
        line-height: 26px;
        border-bottom: 1px solid $linkColor;
    }
}

.error {
    color: $errorColor;
}

.input-wrap {
    position: relative;

    .icon {
        position: absolute;
        left: 20px;
        top: 15px;
        font-size: 20px;
        pointer-events: none;
        z-index: 9;
    }

    label.error {
        display: block;
        margin-top: 5px;
        font-size: 14px;
        color: $errorColor;
    }
}

.input, .textarea {
    width: 100%;
    margin: 0;
    padding: 25px 30px;
    background: $white;
    color: $black;
    border: 1px solid $borderColor;
    font-family: $helvetica;
    font-size: 16px;
    font-weight: 300;
    box-sizing: border-box;
    border-radius: 5px;
    -webkit-appearance: none;

    &::placeholder {
        color: $darkGrayOpacity;
    }

    &.error {
        border-color: $errorColor;
    }
}

.textarea {
    min-height: 200px;
    max-height: 200px;
}

@-webkit-keyframes autofill {
    to {
        color: inherit;
        font-size: 16px;
        background: $white;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    font-size: 16px;
}

.select-wrap {
    position: relative;
    border: 1px solid $borderColor;
    border-radius: 5px;
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 100%;
        background: $white;
        pointer-events: none;
        z-index: 1;
    }
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        border-style: solid;
        border-width: 6px 5px 0;
        border-color: $black transparent transparent;
        pointer-events: none;
        z-index: 5;
    }
}
.select,
select {
    width: 100%;
    height: 70px;
    margin: 0;
    padding: 25px 60px 25px 30px;
    background: $white;
    -webkit-appearance:none;
    color: $black;
    font-family: $helvetica;
    font-size: 16px;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    border-radius: 5px;
    box-sizing: border-box;

    &::placeholder {
        color: $darkGrayOpacity;
    }

    &:invalid {
        color: $darkGrayOpacity;
    }

    option {
        padding: 25px 60px 25px 30px;
    }
}


.checkbox-wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }

    .label {
        margin: 0 0 0 10px;
        font-size: 16px;
        line-height: 201.65%;
        cursor: pointer;
    }
}

.checkbox {
    position: relative;
    display: flex;
    flex: 100%;
    margin: 16px 0 0;

    &:first-child {
        margin-top: 0;
    }

    input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 99;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        background: none;
        border: 1px solid $borderColor;
        border-radius: 4px;

        &:before {
            margin: 1px 0 0 1px;
            font-size: 8px;
        }
    }

    input:checked + .icon {
        background: $themeColor;
        border-color: $themeColor;

        &:before {
            opacity: 1;
        }
    }

    .label {
        font-size: 14px;
        line-height: 20px;
    }
}


/***/

.search-wrap {
    position: relative;
    display: flex;
    align-items: center;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 0 20px $shadow;

    input {
        width: 100%;
        padding: 15px 80px 15px 40px;
        background: none;
        font-size: 16px;
        border: 0;

        &::placeholder {
            color: $darkGrayOpacity;
        }
    }

    .button {
        position: absolute;
        top: 50%;
        right: 30px;
        padding: 0;
        background: none;
        border: 0;
        transform: translateY(-50%);
        cursor: pointer;
    }
}


/*** Tabs ***/

.tabs {
    margin-top: 120px;

    &:first-child {
        margin-top: 0;
    }

    &-head {
        display: flex;
        flex-wrap: wrap;
        font-size: 24px;
        color: $black;
        font-family: $proximaNovaLight;

        .delimiter {
            padding: 0 30px;
            font-size: 45px;
            line-height: 40px;
        }

        &-item {
            padding-bottom: 10px;
            color: $linkColor;
            font-size: 45px;
            line-height: 40px;
            text-align: left;
            text-decoration: none;
            border-bottom: 1px solid $linkColor;
            cursor: pointer;

            &:first-child {
                border-left: 0;
            }

            &.active {
                color: $black;
                text-decoration: none;
                border: 0;
            }
        }
    }

    &-body {
        margin-top: 55px;

        &-item {
            display: none;

            &.active {
                display: block;
            }
        }
    }
}


/***/

.button-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    max-width: 200px;

    .btn,
    .remove {
        margin-top: 20px;

        &:first-child {
            margin-top: 0;
        }
    }

    .btn {
        width: 100%;
        padding: 15px 30px;
        font-size: 18px;
    }

    .remove {
        margin-top: 26px;
        padding-bottom: 8px;
        font-size: 16px;
        color: $red;
        border-bottom: 1px solid $redLight;

        .icon {
            margin-right: 10px;
        }
    }
}


/***/

.pagination {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 100px 0 0;

    &--item {
        min-height: 50px;
        display: flex;
        align-items: center;
        padding: 14px 20px;
        font-size: 18px;
        line-height: 18px;
        font-family: $helveticaMedium;
        color: $black;
        letter-spacing: -0.2px;
        border: 1px solid $borderColor;
        border-left-width: 0;

        &:first-child {
            border-left-width: 1px;
        }

        &.active {
            background: $blue;
            color: $white;
        }

        &.first,
        &.last {
            padding-left: 40px;
            padding-right: 40px;
            border-radius: 5px 0 0 5px;
        }
        &.last {
            border-radius: 0 5px 5px 0;
        }
        &.prev {
            img {
                transform: rotate(180deg);
            }
        }
    }
}


@import '_edit-elements',
        '_header.scss',
        '_footer.scss',
        '_content.scss',
        '_media.scss';