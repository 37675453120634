.header {
    position: relative;
    z-index: 9;

    .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 60px 45px;
    }

    .left-block {
        display: flex;
        align-items: center;
    }
    .right-block {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        padding-left: 50px;
    }

    .logo-wrap {
        display: flex;
        align-items: center;
    }

    .menu-btn {
        display: none;
    }

    .logo {
        margin-right: 30px;
        font-family: $proximaNovaExtraBold;
        font-size: 32px;
        color: $black;
    }

    .site-name {
        max-width: 150px;
        font-size: 12px;
        line-height: 12px;
        color: $black;
        opacity: 0.58;
    }

    .menu {
        display: flex;
        align-items: center;

        &--item {
            display: flex;
            align-items: center;
            margin-left: 40px;
            padding: 25px 0;
            font-family: $proximaNova;
            font-size: 17px;
            color: $black;

            &:first-child {
                margin-left: 0;
            }

            .icon {
                margin-right: 10px;
            }
        }
    }

    .advertising {
        display: flex;
        margin: -20px 0 0 -50px;

        &-wrap {
            margin: 0 0 0 50px;
        }

        &--item {
            display: flex;
            flex: 1;
            position: relative;
            max-width: 330px;
            margin: 20px 0 0 50px;
            font-family: $proximaNova;
            background: $white;
            border-radius: 5px;
            box-shadow: 0 0 16px $shadow;

            .image {
                position: relative;
                flex: 1;
                max-width: 200px;
                border-radius: 5px 0 0 5px;
                overflow: hidden;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .icon {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%) translateX(-50%);

                img {
                    height: auto;
                }
            }

            .text {
                display: flex;
                align-items: center;
                flex: 1;
                padding: 20px 40px 20px 20px;
                font-size: 16px;
                line-height: 16px;
                color: $black;

                span {
                    display: block;
                }
            }
        }
    }
}

.main-page {
    .header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}


.mobile-menu-wrap {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    z-index: 999;

    &.open {
        display: block;
    }

    .header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-bottom: 0;
    }

    .menu-btn-close {
        max-width: 20px;
    }

    .menu-wrap {
        max-height: 80vh;
        margin-top: 20vh;
        padding: 20px 0 15vh;
        overflow: auto;
    }

    .search-wrap {
        box-shadow: 0 0 20px $shadow;

        input {
            padding: 24px 70px 20px 30px;
        }
    }

    .btn {
        width: 100%;
        margin-top: 30px;
        padding: 24px 30px;
    }

    .menu {
        margin-top: 60px;

        &--item {
            display: flex;
            margin-top: 30px;
            padding: 0;
            font-family: $proximaNova;
            font-size: 24px;
            color: $black;

            &:first-child {
                margin-top: 0;
            }

            .icon {
                margin-right: 20px;
                padding-top: 4px;
            }
        }
    }

    .services-block {
        margin-top: -20px;

        &-wrap {
            margin-top: 80px;
        }

        &--item {
            min-height: 0;
            margin-top: 20px;
        }

        .image {
            max-width: 130px;
        }

        .text {
            max-width: 300px;
            padding: 16px 40px;
            font-size: 23px;
        }
    }
}


@media (max-width: 1601px) {
    .header {
        .right-block {
            justify-content: flex-end;
            flex-wrap: wrap;
        }
        .button-wrap {
            margin-top: 20px;

            .call-btn {
                margin: 0;
            }
        }

        .advertising-wrap {
            width: 100%;
            max-width: 550px;
        }
    }
}

@media (max-width: 1281px) {
    .header {
        .wrap {
            padding-left: 40px;
            padding-right: 40px;
        }

        .left-block {
            display: block;
        }

        .logo-wrap {
            display: block;
        }

        .site-name {
            margin-top: 5px;
        }

        .logo {
            margin: 0;
        }

        .menu {
            &--item {
                margin-left: 20px;
            }
        }

        .advertising {
            margin-left: -20px;

            &-wrap {
                margin-left: 0;
            }

            &--item {
                margin-left: 20px;

                .text {
                    padding: 20px 10px;
                }
            }
        }
    }
}

@media (max-width: 769px) {
    .header {
        .wrap {
            padding: 40px 40px 25px;
        }

        .left-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 100%;
        }
        .right-block {
            display: none;
        }

        .site-name {
            display: none;
        }

        .menu-btn {
            display: block;
        }
    }

    .mobile-menu-wrap {
        .header {
            .wrap {
                padding-bottom: 0;
            }
        }
    }
}

@media (max-width: 561px) {
    .header {
        .wrap {
            padding: 40px 20px 20px;
        }

        .logo {
            font-size: 24px;
        }

        .menu-btn {
            max-width: 30px;
        }
    }

    .mobile-menu-wrap {
        .btn {
            margin-top: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
        }

        .search-wrap {
            input {
                padding: 15px 50px 15px 20px;
            }
        }

        .menu {
            &--item {
                margin-top: 20px;
                font-size: 18px;

                .icon {
                    padding-top: 3px;

                    img {
                        max-height: 15px;
                    }
                }
            }
        }

        .services-block {
            .text {
                padding: 15px 20px;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}