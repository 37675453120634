/****** Fonts ******/

@font-face {
    font-family: 'proxima_novabold_italic';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_bold_italic-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_bold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novabold';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_bold-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novaextrabold';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_extrabold-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novaextrabold_italic';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_extrabold_italic-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_extrabold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_nova_ltlight';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_light-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novalight_italic';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_light_italic-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_light_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novaregular_italic';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_regular_italic-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_regular_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_regular-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novasemibold_italic';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_semibold_italic-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_semibold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_nova_ltsemibold';
    src: url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_semibold-webfont.woff2') format('woff2'),
    url('../fonts/ProximaNova/mark_simonson_-_proxima_nova_semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/*** Helvetica ***/

@font-face {
    font-family: 'helveticaneuecyrblack';
    src: url('../fonts/Helvetica/helveticaneuecyr-black-webfont.eot');
    src: url('../fonts/Helvetica/helveticaneuecyr-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/helveticaneuecyr-black-webfont.woff2') format('woff2'),
    url('../fonts/Helvetica/helveticaneuecyr-black-webfont.woff') format('woff'),
    url('../fonts/Helvetica/helveticaneuecyr-black-webfont.ttf') format('truetype'),
    url('../fonts/Helvetica/helveticaneuecyr-black-webfont.svg#helveticaneuecyrblack') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helveticaneuecyrblack_italic';
    src: url('../fonts/Helvetica/helveticaneuecyr-blackitalic-webfont.eot');
    src: url('../fonts/Helvetica/helveticaneuecyr-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/helveticaneuecyr-blackitalic-webfont.woff2') format('woff2'),
    url('../fonts/Helvetica/helveticaneuecyr-blackitalic-webfont.woff') format('woff'),
    url('../fonts/Helvetica/helveticaneuecyr-blackitalic-webfont.ttf') format('truetype'),
    url('../fonts/Helvetica/helveticaneuecyr-blackitalic-webfont.svg#helveticaneuecyrblack_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helveticaneuecyrbold';
    src: url('../fonts/Helvetica/helveticaneuecyr-bold-webfont.eot');
    src: url('../fonts/Helvetica/helveticaneuecyr-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/helveticaneuecyr-bold-webfont.woff2') format('woff2'),
    url('../fonts/Helvetica/helveticaneuecyr-bold-webfont.woff') format('woff'),
    url('../fonts/Helvetica/helveticaneuecyr-bold-webfont.ttf') format('truetype'),
    url('../fonts/Helvetica/helveticaneuecyr-bold-webfont.svg#helveticaneuecyrbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helveticaneuecyrbold_italic';
    src: url('../fonts/Helvetica/helveticaneuecyr-bolditalic-webfont.eot');
    src: url('../fonts/Helvetica/helveticaneuecyr-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/helveticaneuecyr-bolditalic-webfont.woff2') format('woff2'),
    url('../fonts/Helvetica/helveticaneuecyr-bolditalic-webfont.woff') format('woff'),
    url('../fonts/Helvetica/helveticaneuecyr-bolditalic-webfont.ttf') format('truetype'),
    url('../fonts/Helvetica/helveticaneuecyr-bolditalic-webfont.svg#helveticaneuecyrbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helveticaneuecyritalic';
    src: url('../fonts/Helvetica/helveticaneuecyr-italic-webfont.eot');
    src: url('../fonts/Helvetica/helveticaneuecyr-italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/helveticaneuecyr-italic-webfont.woff2') format('woff2'),
    url('../fonts/Helvetica/helveticaneuecyr-italic-webfont.woff') format('woff'),
    url('../fonts/Helvetica/helveticaneuecyr-italic-webfont.ttf') format('truetype'),
    url('../fonts/Helvetica/helveticaneuecyr-italic-webfont.svg#helveticaneuecyritalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helveticaneuecyrlight';
    src: url('../fonts/Helvetica/helveticaneuecyr-light-webfont.eot');
    src: url('../fonts/Helvetica/helveticaneuecyr-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/helveticaneuecyr-light-webfont.woff2') format('woff2'),
    url('../fonts/Helvetica/helveticaneuecyr-light-webfont.woff') format('woff'),
    url('../fonts/Helvetica/helveticaneuecyr-light-webfont.ttf') format('truetype'),
    url('../fonts/Helvetica/helveticaneuecyr-light-webfont.svg#helveticaneuecyrlight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helveticaneuecyrlight_italic';
    src: url('../fonts/Helvetica/helveticaneuecyr-lightitalic-webfont.eot');
    src: url('../fonts/Helvetica/helveticaneuecyr-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/helveticaneuecyr-lightitalic-webfont.woff2') format('woff2'),
    url('../fonts/Helvetica/helveticaneuecyr-lightitalic-webfont.woff') format('woff'),
    url('../fonts/Helvetica/helveticaneuecyr-lightitalic-webfont.ttf') format('truetype'),
    url('../fonts/Helvetica/helveticaneuecyr-lightitalic-webfont.svg#helveticaneuecyrlight_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helveticaneuecyrmedium';
    src: url('../fonts/Helvetica/helveticaneuecyr-medium-webfont.eot');
    src: url('../fonts/Helvetica/helveticaneuecyr-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/helveticaneuecyr-medium-webfont.woff2') format('woff2'),
    url('../fonts/Helvetica/helveticaneuecyr-medium-webfont.woff') format('woff'),
    url('../fonts/Helvetica/helveticaneuecyr-medium-webfont.ttf') format('truetype'),
    url('../fonts/Helvetica/helveticaneuecyr-medium-webfont.svg#helveticaneuecyrmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helveticaneuecyrroman';
    src: url('../fonts/Helvetica/helveticaneuecyr-roman-webfont.eot');
    src: url('../fonts/Helvetica/helveticaneuecyr-roman-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/helveticaneuecyr-roman-webfont.woff2') format('woff2'),
    url('../fonts/Helvetica/helveticaneuecyr-roman-webfont.woff') format('woff'),
    url('../fonts/Helvetica/helveticaneuecyr-roman-webfont.ttf') format('truetype'),
    url('../fonts/Helvetica/helveticaneuecyr-roman-webfont.svg#helveticaneuecyrroman') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helveticaneuecyrthin';
    src: url('../fonts/Helvetica/helveticaneuecyr-thin-webfont.eot');
    src: url('../fonts/Helvetica/helveticaneuecyr-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/helveticaneuecyr-thin-webfont.woff2') format('woff2'),
    url('../fonts/Helvetica/helveticaneuecyr-thin-webfont.woff') format('woff'),
    url('../fonts/Helvetica/helveticaneuecyr-thin-webfont.ttf') format('truetype'),
    url('../fonts/Helvetica/helveticaneuecyr-thin-webfont.svg#helveticaneuecyrthin') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'helveticaneuecyrthin_italic';
    src: url('../fonts/Helvetica/helveticaneuecyr-thinitalic-webfont.eot');
    src: url('../fonts/Helvetica/helveticaneuecyr-thinitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/helveticaneuecyr-thinitalic-webfont.woff2') format('woff2'),
    url('../fonts/Helvetica/helveticaneuecyr-thinitalic-webfont.woff') format('woff'),
    url('../fonts/Helvetica/helveticaneuecyr-thinitalic-webfont.ttf') format('truetype'),
    url('../fonts/Helvetica/helveticaneuecyr-thinitalic-webfont.svg#helveticaneuecyrthin_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}


$helveticaLight: 'helveticaneuecyrlight', sans-serif;
$helvetica: 'helveticaneuecyrroman', sans-serif;
$helveticaMedium: 'helveticaneuecyrmedium', sans-serif;
$helveticaBold: 'helveticaneuecyrbold', sans-serif;
$proximaNovaLight: 'proxima_nova_ltlight', sans-serif;
$proximaNova: 'proxima_nova_rgregular', sans-serif;
$proximaNovaSemiBold: 'proxima_nova_ltsemibold', sans-serif;
$proximaNovaBold: 'proxima_novabold', sans-serif;
$proximaNovaExtraBold: 'proxima_novaextrabold', sans-serif;


/*** Colors ***/

$white: #ffffff;
$black: #000000;
$blackLight: #1C1C1C;
$blackLightOther: #222222;
$blue: #2381f3;
$red: #ff0000;
$redLight: rgba(255, 0, 0, 0.3);;
$grayLight: #d6d6d6;
$gray: #C4C4C4;
$grayDim: #adadad;
$darkGray: #595959;
$darkGrayOther: #4b4b4b;
$darkGrayOpacity: rgba(89, 89, 89, 0.84);
$orange: #FF881B;
$pink: #F469AC;
$pinkLight: #FDF1E5;
$greenLight: #E5F1FF;

$themeColor: #1f7ef3;
$textColor: #585757;
$textGray: $darkGray;
$linkColor: #1975c4;
$borderColor: #929292;
$shadow: rgba(0, 0, 0, 0.13);
$errorColor: #ca4c60;